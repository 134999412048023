/*
 * 업무구분 : 청약
 * 화면 명 : PSListSortOpt
 * 화면 설명: 청약 파트 정렬 컴포너트
 */
<template>
  <msp-bottom-select ref="bottomSelect" 
    :items="sortFieldList" 
    :placeholder="defaultLabel" 
    :bottom-title="popupTitle" 
    :itemValue="itemValue" 
    :itemText="itemText"
    v-model="slctFieldObj"
    @input="fn_OnSelect" 
    underline 
    class="ns-dropdown-sheet maxW90 type-1" 
    size="small"     
    closeBtn/>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import PSConstants from '@/config/constants/psConstants'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'PSListSortOpt', 
  screenId: 'PSListSortOpt', 
  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    popupTitle   : {type:String, default: '정렬선택'},
    defaultLabel : {type:String, default: '정렬선택'},
    itemValue    : {type:String, default: 'key'},
    itemText     : {type:String, default: 'label'},
    sortFieldList : {type:Array, default: () => null}
  },//props

 
  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {            
      slctFieldObj : null
    }
  },//data

 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    this.fn_Init()
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
    return
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    
  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/
    
    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler () {
      return
    },

    /******************************************************************************
    * Function명  : fn_Init
    * 설명        : 초기화 셋팅 처리
    ******************************************************************************/
    fn_Init () {
      return
    },
  
    /******************************************************************************
    * Function명  : fn_OnSelect
    * 설명        : 선택 체인지 핸들러
    ******************************************************************************/

   fn_Reset () {
      this.slctFieldObj = null;
    },

    /******************************************************************************
    * Function명  : fn_OnSelect
    * 설명        : 선택 체인지 핸들러
    ******************************************************************************/
    fn_OnSelect(rtnVal) {      
      this.$emit('sortChange',this.slctFieldObj)
    },

  /*---------------------------------------------------------------------------------
  * 기타 Util 영역
  ---------------------------------------------------------------------------------*/



  //--------------------------------------------------------------------------------
    _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의
 },// methods

}//export default


</script>