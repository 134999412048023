/*
 * 업무구분 : 청약
 * 화면 명 : PSHeaderSubMenuPopup
 * 화면 설명: 상단 서브 메뉴 팝업
<template>
<!-- :title="popupTitle" :action-type="actionType"-->
  <ur-page-container class="msp btn-back-clear"  :title="popupTitle" :show-title="true" type="popup"  :action-type="actionType">
    <ur-box-container direction="column" alignV="start" class="ns-bottom-sheet closebtn">
      <div class="content-area list-ty full mhAuto">
        <mo-list :list-data="subMenuList">
          <template #list-item="{item}">
            <!-- :class="[{ 'on' : item.data1 === 'on'},{}]"   -->
            <div class="list-li" @click="fn_RowClick(item)">
              <span class="txt txtSkip">{{item.label}}</span>
              <button class="link-arrow"></button>
            </div> 
          </template>
        </mo-list> 
      </div>      
    </ur-box-container>  
  </ur-page-container>  
</template>
<script>
/***********************************************************************************
* 공통 라이브러리 INCLUDE 영역
***********************************************************************************/
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'//
import MSPPI311P from '@/ui/pi/MSPPI311P' // 필요양식 발행 팝업

export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  ***********************************************************************************/
  name      : 'PSHeaderSubMenuPopup',
  screenId  : 'PSHeaderSubMenuPopup',

  components: {
   'MSPPI311P'               : MSPPI311P, // 전자서명 필요양식 밠행
  },//componets,
  
  /***********************************************************************************
  * 화면 UI Property 정의
  ***********************************************************************************/
  props: {
    modalId      : {type:String, default:''},
    popupTitle   : {type:String, default:'서브메뉴'},
    parentSrnId  : {type:String, default:''},
    subMenuList  : {type:Array,  default: () => []  }     
  },


  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      actionType : 'none',
      itemRadioSlctObj : null       
    }
  },

  /***********************************************************************************
  * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created () {
    console.log('PSHeaderSubMenuPopup created()!!!')
    console.log(this.slctFieldObj)
    this.actionType = PSCommUtil.fn_SetPopupAppBarClose(this.fn_Close)
    this.fn_init()
     // backButton event 등록
    // window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
  },
  
  mounted () { 
  },
  
  computed: {
  },
  
  watch: {
  },

  destroyed () {
    console.log('PSHeaderSubMenuPopup destroyed()!!!')
    // window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)  // backButton event 해제
  },//destroyed

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  //팝업 형태 속성
  /*modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },*/

  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    
    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      this.fn_Close()
    },

    /******************************************************************************
     * Function명 : fn_init
     * 설명       : 목록 초기조회
     ******************************************************************************/
    fn_init () {
      return
    },
    /******************************************************************************
     * Function명 : fn_RowClick
     * 설명       : 로우 클릭 이벤트 핸들러
     ******************************************************************************/
    fn_RowClick (sltItem) {
      this.itemRadioSlctObj = sltItem
      this.fn_Enter()
    },
    
    /******************************************************************************
     * Function명 : fn_Enter
     * 설명       : 확인버튼 클릭 이벤트 핸들러
     ******************************************************************************/

    fn_Close () {
      this.$emit('popupEventClose',null)
    },

    /******************************************************************************
     * Function명 : fn_Enter
     * 설명       : 확인버튼 클릭 이벤트 핸들러
     ******************************************************************************/
    fn_Enter () {
      let rtnData = this.itemRadioSlctObj
      let menuParam = null
      let compBottomAlert = MSPBottomAlert
      let routerPushParam = {}

      if(! rtnData) {
        this.$emit('popupEventRouterMove',rtnData)
        return
      }  
      
      menuParam = (rtnData.param)? rtnData.param : null
      
      
      if(rtnData.isConfrimMesg){ // 이동시 Confirm 메세지 존재 체크
        
        let alertModalId = this.$bottomModal.open(compBottomAlert, {
          properties: {
            //single: true,
            title: "",
            content: rtnData.isConfrimMesg,
            title_pos_btn: "이동",
            title_neg_btn: "취소",            
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alertModalId)              
              this.$emit('popupEventRouterMove',rtnData)

              routerPushParam = {name: rtnData.srnId}
              if(menuParam) routerPushParam.params = menuParam
              console.log('routerPushParam',routerPushParam)
              this.$router.push(routerPushParam)
            },
            onPopupClose: () => {
              this.$bottomModal.close(alertModalId)                    
            }
          }
        })

      }else{// 이동시 Confirm 메세지 없으면 그냥 이동 처리        
        this.$emit('popupEventRouterMove',rtnData)
        routerPushParam = {name: rtnData.srnId}
        if(menuParam) routerPushParam.params = menuParam
        console.log('routerPushParam',routerPushParam)
        
        if(routerPushParam.name === 'MSPPI311P'){
          // 필요양식 발행 팝업 열기
          let pi311p = this.$bottomModal.open(MSPPI311P, {
            properties: {
              pParntSrnId: this.$options.screenId // 부모 화면 ID
            },
            listeners: {
              // 팝업 닫기
              closePopup: () => {
                this.$bottomModal.close(pi311p)
              }
            }
          })
        }else{
          this.$router.push(routerPushParam)
        }
     
      }

    },


//--------------------------------------------------------------------------------
   _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의
  }
}
</script>