/*
 * 업무구분: 개인가입설계
 * 화 면 명: MSPPI311P
 * 화면설명: 가입설계관리 > 최근설계 > 필요양식 발행 팝업
 * 작 성 일: 0000.00.00
 * 작 성 자: 
 */
<template>
  <ur-page-container class="msp" title="전자서명 필요양식 발행" :show-title="true" type="subpage" :topButton="true">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list type-4">
        <mo-list :list-data="listItems" style="padding-bottom: 70px;"> <!-- 하단 여백 추개 수정 / ASR240100409 -->
          <template #list-item="{item, index}">
            <mo-list-item expandable :ref="'expItem_'+index">
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name fwn">{{item.title}}</span>
                </div>
              </div>
              <template v-slot:expand class="ns-gray-box">
                <div class="list-item__contents">
                  <mo-list :list-data="item.checkBoxItems" class="pt6 pb6">
                    <template #list-item="{item}">
                      <mo-list-item>
                        <mo-checkbox v-model="item['checked']" class="mr10"/>
                        <div class="list-item__contents">
                          <div class="list-item__contents__info crTy-bk1">
                            <span>{{item.zaPblScNm}}</span>
                          </div>
                        </div>
                      </mo-list-item>
                    </template>
                  </mo-list>
                </div>
              </template>
            </mo-list-item>
          </template>
        </mo-list>
      </ur-box-container>

      <mo-bottom-sheet ref="chAlert" :close-btn="false"  class="ns-bottom-sheet" noHeader>
        <div class="customer-info-txt txt-center">
          <p class="ment">
            출력할 양식을 하나 이상 선택해 주세요.
          </p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="primary" size="border" class="ns-btn-round blue" @click="fn_Close('chAlert')">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <mo-bottom-sheet ref="printAlert" :close-btn="false"  class="ns-bottom-sheet" noHeader>
        <div class="customer-info-txt txt-center">
          <p class="ment" ref="printAlertMent"></p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="primary" size="border" class="ns-btn-round blue" @click="fn_Close('printAlert')">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_NcsFrmPbl()">확인</mo-button>
          </div>
        </ur-box-container>  
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager'

export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPI311P',
  screenId: 'MSPPI311P',
  components: {},
  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props: {
    pParntSrnId : {type : String,  default: () => ''} // 부모 화면 ID
  },
  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data() {
    return {
      listItems: [
        {
          title: "청약 기타 서류",
          checkBoxItems: [
            {zaPblScNm: '계약체결동의서', zaSrcFileNm: 'npipCrdin'}, // 구) 개인(신용)정보 처리 동의서(ASR210700916_필요양식발행화면 서식명 수정 및 대상 서식 추가_사랑온)
            {zaPblScNm: '전자서명 피보험자 서면동의서', zaSrcFileNm: 'pvmza11'},
            {zaPblScNm: '특별조건부 특약 등 신청서', zaSrcFileNm: 'npippvmza9'},
            {zaPblScNm: 'VIP고객을위한질문서', zaSrcFileNm: 'pvmpnbPbfn'},
            {zaPblScNm: '청약서(출금동의用)', zaSrcFileNm: 'pnbau1'},
            // {zaPblScNm: '변경청약 신청서', zaSrcFileNm: 'nbpnu16r05'},
            {zaPblScNm: '실손의료보험 중복가입 안내서', zaSrcFileNm: 'npippvmza11'},
            {zaPblScNm: '보험료 할인조건 충족여부 확인서(고지우량체用)', zaSrcFileNm: 'pvmPrmDisc'}
            // {zaPblScNm: '기타 서비스 안내 신청서', zaSrcFileNm: 'pvmza15'}
          ]
        },
        {
          title: "계약전 알릴의무사항",
          checkBoxItems: [
            {zaPblScNm: '계약전 알릴의무사항_추가고지', zaSrcFileNm: 'pvm_noticeextra'},
            {zaPblScNm: '계약전 알릴의무사항_일반', zaSrcFileNm: 'pvm_notice'},
            {zaPblScNm: '계약전 알릴의무사항_연금저축', zaSrcFileNm: 'pvm_noticePEN'},
            // {zaPblScNm: '계약전 알릴의무사항_간편건강', zaSrcFileNm: 'pvm_noticeENT2'}, // 23.08.04_ASR230701006_(사랑온)전자서명공양식 간편고지 서식 목록 제외
            {zaPblScNm: '계약전 알릴의무사항_간편실손', zaSrcFileNm: 'pvm_noticeENA'},
            {zaPblScNm: '계약전 알릴의무사항_S간편', zaSrcFileNm: 'pvm_noticeQP'},
            {zaPblScNm: '계약전 알릴의무사항_초간편', zaSrcFileNm: 'pvm_noticeUS'},
            {zaPblScNm: '계약전 알릴의무사항_간편간병', zaSrcFileNm: 'pvm_noticeKCM'},
            {zaPblScNm: '계약전 알릴의무사항_일반간병', zaSrcFileNm: 'pvm_noticeCM'},
            {zaPblScNm: '계약전 알릴의무사항_만 15세 미만 가입가능', zaSrcFileNm: 'pvm_noticeKID'}, // 23.08.31_ASR230800683_어린이고지서 개정관련 수정
            {zaPblScNm: '계약전 알릴의무사항_치아', zaSrcFileNm: 'pvm_noticeDT'}
            // 신간편 고지서 - 4종추가 (ASR240900122_이현수_241008 반영)
            ,{zaPblScNm: '계약전 알릴의무사항_1종 1형(간편고지형 3.10.5)',zaSrcFileNm: 'pvm_noticeNENT10'}
            ,{zaPblScNm: '계약전 알릴의무사항_1종 2형(간편고지형 3.5.5)', zaSrcFileNm: 'pvm_noticeNENT5'}
            ,{zaPblScNm: '계약전 알릴의무사항_1종 3형(간편고지형 3.2.5)', zaSrcFileNm: 'pvm_noticeNENT2'}
            ,{zaPblScNm: '계약전 알릴의무사항_1종 4형(간편고지형 3.1.5)', zaSrcFileNm: 'pvm_noticeNENT1'}
          ]
        }
      ],
      inputData: {
        tmodel: {
          zPIHptsPblHstrListDto: {
            zaPblYn: 'Y',
            zPIHptsPblHstrDto: []
          }
        }
      },
      printFormDto: [],
      doClose: false // 팝업 닫을지 여부
    }
  },
  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  // router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨
  // beforeRouteEnter(to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter
  // router에 의해 화면을 벗어날때 호출 
  // beforeRouteLeave(to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave
  /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/
  // beforeCreate() {},
  created() {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
    //받은 파라미터 셋팅
    this.pParntSrnId = this.pParntSrnId ? this.pParntSrnId.trim() : '' // 부모 화면 ID
  },
  // beforeMount() {},
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
    // 청약 기타 서류 펼치기
    this.$refs['expItem_0'].expand()
  },
  // beforeUpdate () {},
  // updated () {},
  // beforeDestroy () {},
  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
  },
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},
  /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},
  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /************************************************************************************************
    * Function명  : fn_NcsFrmPbl
    * 설명        : 필요양식 발행
    ************************************************************************************************/
    fn_NcsFrmPbl() {
      this.printFormDto = []
      this.listItems[0].checkBoxItems.forEach(element => {
        if(element.checked !== undefined && element.checked === true) {
          element.zaPblNos = 1
          this.printFormDto.push(element)
        }
      })
      this.listItems[1].checkBoxItems.forEach(element => {
        if(element.checked !== undefined && element.checked === true) {
          element.zaPblNos = 1
          this.printFormDto.push(element)
        }
      })
      // 출력 대상이 있는 경우, I/F요청
      if(this.printFormDto.length > 0) {
        this.inputData.tmodel.zPIHptsPblHstrListDto.zPIHptsPblHstrDto = this.printFormDto
        // txTSSPS21T1 : 전자서명 필요양식발행
        let pParams = {}
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS21T1'))
        pParams.data.cmndSrvcNm = 'eltrnSignNcsForm'
        pParams.data.sdrvDtVal = JSON.stringify(this.inputData) // JSON문자열로 변환
        // 서비스 호출
        window.vue.getStore('progress').dispatch('FULL') // full loading setting
        PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, null, this.fn_ServiceFaultHandler)
      }else {
        // '항목을 하나 이상 체크해 주세요.' 알림 오픈
        this.$refs.chAlert.open()
        this.doClose = false
      }
    },
    /************************************************************************************************
    * Function명  : fn_Close
    * 설명        : [전자서명 필요양식발행] 닫기
    ************************************************************************************************/
    fn_Close(el) {
      // 바텀시트 닫고
      (el === 'chAlert') ? this.$refs.chAlert.close() : this.$refs.printAlert.close()
      if(this.doClose) {
        // 팝업 닫고
        this.$emit('closePopup')
      }
    },
    /******************************************************************************
    * Function명  : fn_ServiceResultHandler
    * 설명        : 서비스 호출결과 함수
    ******************************************************************************/
    fn_ServiceResultHandler(event) {
      let lv_data = event.data
      let outputData = JSON.parse(lv_data.sdrvDtVal)
      // 처리결과가 정상인 경우
      if (outputData.tmodel.zPIHptsPblHstrListDto.zaTrnsRsltCd === 'Y') {
        // 결과 메시지를 출력 : 정상 발행되었습니다.
        let tmpMsg = ''
        tmpMsg = outputData.tmodel.zPIHptsPblHstrListDto.zaTrnsRsltCntnt + '.<br>'
        let tmpPIHptsPblHstrDto = outputData.tmodel.zPIHptsPblHstrListDto.zPIHptsPblHstrDto
        tmpPIHptsPblHstrDto.forEach(el => {
          tmpMsg += '<br/>' + el.zaPblScNm
        })
        this.$refs.printAlertMent.innerHTML = tmpMsg
        this.$refs.printAlert.open()
        this.doClose = true
      }
    },
    /******************************************************************************
    * Function명  : fn_ServiceFaultHandler
    * 설명        : 서비스 호출결과 함수
    ******************************************************************************/
    fn_ServiceFaultHandler(event) {
      this.$refs.printAlertMent.innerHTML = '발행에 실패하였습니다.'
      this.$refs.printAlert.open()
      this.doClose = false
    },

    // 코딩 종료 함수
    _fn_End(){return}
  }
}
</script>