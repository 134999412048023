let PSConstants = {}

 /***************************
  * U설계청약진행현황 관련
  ***************************/

  //설계청약진행현황 검색옵션 고객명
  PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM = {key: '1', label: '고객명'}
  //설계청약진행현황 검색옵션 상품명
  PSConstants.PLAN_OFR_SEARCH_OPT_PRDT_NM = {key: '2', label: '상품명'}
  //설계청약진행현황 검색옵션 영수증번호
  PSConstants.PLAN_OFR_SEARCH_OPT_VUCH_NUM = {key: '3', label: '영수증번호'}

  //설계청약진행현황 검색옵션 List
  PSConstants.PLAN_OFR_SEARCH_OPT_LIST = [
    PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM,
    PSConstants.PLAN_OFR_SEARCH_OPT_PRDT_NM,
    PSConstants.PLAN_OFR_SEARCH_OPT_VUCH_NUM
  ]
  
  //계약자 AML formnm
  PSConstants.FORM_NM_AML_CONTR       = 'pvm_custkycctr'
  //법인단체 계약자 AML formnm
  PSConstants.FORM_NM_CORP_AML_CONTR  = 'pvm_entkyc'
  //친권자01 AML formnm 
  PSConstants.FORM_NM_AML_PRTR01      = 'pvm_custkycprx' 
  //사망수익자01 AML formnm 
  PSConstants.FORM_NM_AML_DTH_BNFR01  = 'pvm_custkycben'
  
  //AML 서식 리스트
  PSConstants.FORM_NM_AML_LIST =[
     PSConstants.FORM_NM_AML_CONTR
    ,PSConstants.FORM_NM_CORP_AML_CONTR
    ,PSConstants.FORM_NM_AML_PRTR01
    ,PSConstants.FORM_NM_AML_DTH_BNFR01 
  ]

/*===================================================
  사용법 
  1.import 기본
    import PSConstants from '@/config/constants/psConstants'
  
  2.확장자 vue 파일 일때
   data(){
     return {
       PSConstants : PSConstants,
       .......
     }
   } 
  3. ui tag :bind 사용시
   <mo-list :list-data="PSConstants.PLAN_OFR_SEARCH_OPT_LIST">
  4.ui 출력 사용시
   <span class="sum">{{PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.label}}</span>   
  5. script 사용시
   if(PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key == this.selectedItem.key) {.....}
 ===================================================*/

 
export default Object.freeze(PSConstants)
